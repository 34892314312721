import { unionBy } from "lodash";
// **  Initial State
const initialState = {
  bookings: [],
  homeData: null,
  isAuthenticated: false,
  isLoading: null,
  profilePhoto: null,
  status: null,
  userData: null,
  userName: null,
  isScroll: false,
  pastGames: null,
  isLoadingPast: false,
  leagueInvite: null,
  urlShared: null,
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case "LOADER_START":
      return {
        ...state,
        isLoading: true,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        userData: {
          ...state.userData,
          ...action.payload.userData,
        },
        userName: action.payload.userName,
        isLoading: false,
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
        },
      };
    case "LOADER_STOP":
      return {
        ...state,
        isLoading: false,
      };
    case "LOADER_SCROLL_START":
      return {
        ...state,
        isScroll: true,
      };
    case "LOADER_SCROLL_STOP":
      return {
        ...state,
        isScroll: false,
      };
    case "SET_AVATAR":
      return {
        ...state,
        profilePhoto: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        userData: null,
        userName: null,
        profilePhoto: null,
        isLoading: null,
      };
    case "SET_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "SET_HOME_DATA":
      return {
        ...state,
        homeData: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: state.homeData
            ? [...state.homeData.results, ...action.payload.results]
            : action.payload.results,
        },
      };
    case "LOADER_PAST_START":
      return {
        ...state,
        isLoadingPast: true,
      };
    case "LOADER_PAST_STOP":
      return {
        ...state,
        isLoadingPast: false,
      };
    case "SET_PAST_GAME":
      return {
        ...state,
        pastGames: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: state.pastGames
            ? [...state.pastGames.results, ...action.payload.results]
            : action.payload.results,
        },
      };
    case "SET_BOOKING_LIST":
      return {
        ...state,
        bookings: unionBy(state.bookings, action.payload, "booking_id"),
      };
    case "CLEAR_STATUS":
      return {
        ...state,
        status: null,
      };
    case "CLEAR_BOOKINGS":
      return {
        ...state,
        bookings: [],
        homeData: null,
      };
    case "CLEAR_BOOKINGS_PAST":
      return {
        ...state,
        pastGames: null,
      };
    case "SET_CREATE_CUSTOMER":
      return {
        ...state,
        userData: {
          ...state.userData,
          stripe_customer_id: action.payload,
        },
      };
    case "INVITE_TOKEN":
      return {
        ...state,
        userData: {
          ...state.userData,
          invite_token: action.payload,
        },
      };
    case "DECLINE_INVITE":
      return {
        ...state,
        userData: {
          ...state.userData,
          invite_token: null,
        },
      };
    case "LEAGUE_INVITE_START":
      return {
        ...state,
        leagueInvite: action.payload,
      };
    case "LEAGUE_URL_SHARED":
      return {
        ...state,
        urlShared: action.payload,
      };
    case "LEAGUE_INVITE_STOP":
      return {
        ...state,
        leagueInvite: null,
      };
    default:
      return state;
  }
};
