/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const AuthenticatedRoute = ({ children, redirectTo }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    useEffect(() => {
        if (!isAuthenticated) {
            // Store the current path in Redux
            console.log(location.pathname + location.search);
            dispatch({
                type: "LEAGUE_URL_SHARED",
                payload: location.pathname + location.search,
              });
        }
    }, [dispatch, isAuthenticated, location.pathname]);
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
}
export default React.memo(AuthenticatedRoute);
